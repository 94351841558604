<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <div class="table-form">
        <div class="box-ct" >
          <v-data-table
            dense
            height="350px"
            :headers="divGridAuthGroup"
            :items="gridDataText"
            :item-class="isActiveRow"
            :items-per-page="30"
            item-key="ATRT_GROUP_ID"
            :page.sync="page"
            hide-default-footer
            class="grid-default"
            @page-count="pageCount = $event"
            v-on:click:row="getGrid2ListSel"
          >
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
          <p class="caption text-right">
            <strong class="clr-error">{{ gridDataText.length }}</strong
            >건의 공통코드가 검색되었습니다.
          </p>
        </div>

        <div >
          <h2 class="tit-h2 d-flex">상세정보</h2>
          <div class="mt-3">
            <v-form ref="form"
                    v-model="valid"
                    lazy-validation
            >
            <div class="table-form">
              <table>
                <caption class="hide">
                  정보 항목
                </caption>
                <colgroup>
                  <col width="127px" />
                  <col width="310px" />
                  <col width="127px" />
                  <col width="310px" />
                  <col width="127px" />
                  <col width="" />
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row"><v-badge dot color="#EF9191">그룹명</v-badge></th>
                  <td colspan="5">
                    <v-text-field
                      class="form-inp full"
                      name="ATRT_GROUP_NM"
                      id="id"
                      outlined
                      placeholder="그룹명"
                      :rules="validateRules.artrGroupNm"
                      v-model="selArtrGroupNm.ATRT_GROUP_NM"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><v-badge dot color="#EF9191">우선순위</v-badge></th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="ETC_INFO01"
                      id="id"
                      outlined
                      placeholder="우선순위"
                      :rules="validateRules.etcInfo01"
                      v-model="selArtrGroupNm.ETC_INFO01"
                    >
                    </v-text-field></td>
                  <th scope="row"><v-badge dot color="#EF9191">관리자여부</v-badge></th>
                  <td>
                    <v-select
                      :items="menuGroup2"
                      name="MNGR_YN"
                      class="form-inp full"
                      item-text="name"
                      item-value="value"
                      outlined
                      placeholder="선택"
                      :rules="validateRules.mngrYn"
                      v-model="selArtrGroupNm.MNGR_YN"
                    ></v-select>
                  </td>
                  <th scope="row"><v-badge dot color="#EF9191">사용자구분</v-badge></th>
                  <td>
                    <v-select
                      :items="menuGroup3"
                      name="ETC_INFO02"
                      class="form-inp full"
                      item-text="name"
                      item-value="value"
                      outlined
                      placeholder="선택"
                      :rules="validateRules.etcInfo02"
                      v-model="selArtrGroupNm.ETC_INFO02"
                    ></v-select>

                  </td>
                </tr>
                <tr>
                  <th scope="row">그룹설명</th>
                  <td colspan="5">
                    <v-text-field
                      class="form-inp full"
                      name="GROUP_XPLN"
                      id="id"
                      outlined
                      placeholder="그룹설명"
                      :rules="validateRules.groupXpln"
                      v-model="selArtrGroupNm.GROUP_XPLN"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">그룹ID</th>
                  <td colspan="5">
                    <v-text-field
                      class="form-inp full"
                      name="ATRT_GROUP_ID"
                      id="id"
                      outlined
                      disabled
                      placeholder="그룹ID"
                      :rules="validateRules.atrtGroupId"
                      v-model="selArtrGroupNm.ATRT_GROUP_ID"
                    >
                    </v-text-field>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            </v-form>
          </div>
        </div>

      </div>
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="USER_AUTH_CHK" outlined class="btn-default ml-3" @click="initUser">신규</v-btn>
        <v-btn v-if="USER_AUTH_CHK" outlined class="btn-etc ml-2" @click="popDelete" >삭제</v-btn>
        <v-btn v-if="USER_AUTH_CHK" outlined class="btn-point ml-2" @click="popSubmit">저장</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/store/apiUtil";
  import {mixin} from "@/mixin/mixin";

  export default {
    name: "MENU_M810703P01", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
      headerTitle: String,
    },
    mixins:[mixin],
    data(){
      return {
        type:'',
        REGR_DEPT_CD:'',
        Org_EtcInfo01:'',
        Org_MngrYn:'',
        Org_artrGroupId:'',
        Org_GroupXpln:'',
        Org_ArtrGroupNm:'',
        Org_etcInfo02:'',
        etcInfo01:'',
        etcInfo02:'',
        etcInfo03:'',
        MngrYn:'',
        menuCnt:'',
        artrGroupNm:'',
        artrGroupId:'',
        groupXpln:'',
        menu1: false,
        menu2: false,
        date: {},
        date2: {},
        valid: true,
        validateRules:{
          artrGroupNm: [
            v => !!v || '그룹명은(는) 필수입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          etcInfo01: [
            v => !!v || '우선순위은(는)  필수입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          mngrYn: [
            v => !!v || '관리자여부 은(는)  필수입니다.',
          ],
          newUseYn: [
            v => !!v || '사용여부 은(는)  필수입니다.',
          ],
          etcInfo02: [
            v => !!v || '사용자구분 은(는)  필수입니다.',
          ],
        },
        footerHideTitle: "닫기",

        divGridAuthGroup: [
          {
            text: "그룹명",
            value: "ATRT_GROUP_NM",
            align: "center",
            sortable: true,
          },
          {
            text: "우선순위",
            value: "ETC_INFO01",
            align: "center",
            sortable: true
          },
          // {
          //   text: "메뉴개수",
          //   value: "MENU_CNT",
          //   align: "center",
          //   sortable: true,
          // },
          {
            text: "그룹ID",
            value: "ATRT_GROUP_ID",
            align: "center",
            sortable: true,
          },
        ],
        menuGroup2: [
          { name: "허용", value:"Y" },
          { name: "미허용", value:"N" },
        ],
        menuGroup3: [
          { name: "손님", value:"01" },
          { name: "관리자", value:"00" },
        ],

        gridDataText: [],
        selArtrGroupNm:{},
        gridRowDetail: "",

        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 10,

        textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
        textareaValue: "200자 제한!",

        USER_AUTH_CHK: false,
      }
    },

      methods:{
        validate () {
          return this.$refs.form.validate();
        },
        initUser(){
          this.type = "I"
            console.log("상태 메시지"+this.type);
            this.selArtrGroupNm.ATRT_GROUP_ID = "";
            this.selArtrGroupNm.ATRT_GROUP_NM = "";
            this.selArtrGroupNm.ETC_INFO01 = "";
            this.selArtrGroupNm.MNGR_YN = "";
            this.selArtrGroupNm.ETC_INFO02 = "";
            this.selArtrGroupNm.GROUP_XPLN = "";
        },
        getGrid2ListSel(item){
          this.selArtrGroupNm = JSON.parse(JSON.stringify(item));

        },
        isActiveRow(item){
          return item.ATRT_GROUP_ID== this.selArtrGroupNm.ATRT_GROUP_ID? 'active':'';
        },
        async getGrid2(){
          let requestData = {
            headers: {},
            sendData:{}
          };
          // 3개 탭 정보를 보내야 함. 먼저 접수지연
          // header 세팅
          requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/inqire";
          requestData.headers["METHOD"] = "inqire";
          requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
          requestData.headers["ASYNC"] = false;
          //this.selArtrGroupNm = '';
          let response = await this.common_postCall(requestData);
          this.getGrid2CallBack(response);
        },
        async popDelete(){
          let requestData = {
            headers: {},
            sendData:{}
          };
          // 3개 탭 정보를 보내야 함. 먼저 접수지연
          // header 세팅
          requestData.headers["URL"] = "/api/setting/system/menu-author-manage/asgn-menu-by-group/delete";
          requestData.headers["METHOD"] = "delete";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["SERVICE"] = "setting.system.menu-author-manage.asgn-menu-by-group";
          requestData.headers["ASYNC"] = false;
          let deleteList = [];
          let Obj = {
            DATA_FLAG:"DP",
            ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
          }
          deleteList.push(Obj);
          let Obj2 = {
            DATA_FLAG:"DU",
            ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
          }
          deleteList.push(Obj2);
          let Obj3 = {
            DATA_FLAG:"DG",
            ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
          }
          deleteList.push(Obj3);
          requestData.sendData  = {
            list:deleteList
          }
          let response = await this.common_postCall(requestData);
          this.popDeleteCallBack(response);
        },
        popDeleteCallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
          this.common_alert("정상처리 되었습니다.");
          this.getGrid2();
          this.selArtrGroupNm = "";
          this.$refs.form.resetValidation()
          this.$emit('hideAndGetList');
          return;
        },
        async initUserSbmit(){
          let requestData = {
            headers: {},
            sendData:{}
          };
          requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group-nm-dplct-ceck/inqire";
          requestData.headers["METHOD"] = "inqire";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-nm-dplct-ceck";
          requestData.headers["ASYNC"] = false;

          requestData.sendData = {};
          requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
          requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
          requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
          requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
          requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
          requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
          requestData.sendData["REGR_DEPT_CD"] = 'x';
          requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
          requestData.sendData["AMDR_DEPT_CD"] = "x";
          let response = await this.common_postCall(requestData);
          this.initUserSbmitCallBack(response);
        },
        initUserSbmitCallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
          if(Object.keys(data).length<1){
            this.initUserIdSbmit();
          }
        },
        async initUserIdSbmit(){
          let requestData = {
            headers: {},
            sendData:{}
          };
          requestData.headers["URL"] = "/api/innb/common/generate-unique-no/inqry";
          requestData.headers["METHOD"] = "inqry";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
          requestData.headers["ASYNC"] = false;

          requestData.sendData = {};
          requestData.sendData["BIZ_CASE"] = "TWB";
          requestData.sendData["REGR_DEPT_CD"] = 'x';
          let response = await this.common_postCall(requestData);
          this.initUserIdSbmitCallBack(response);
        },
        initUserIdSbmitCallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
          this.selArtrGroupNm.ATRT_GROUP_ID = data[0].RET_VAL;
          this.initUserRegistSbmit();
        },
        async initUserRegistSbmit(){

          if(!this.validate()){
            return;
          }
          let requestData = {
            headers: {},
            sendData:{}
          };
          requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/regist";
          requestData.headers["METHOD"] = "regist";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
          requestData.headers["ASYNC"] = false;

          requestData.sendData = {};

          requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
          requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
          requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
          requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
          requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
          requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
          requestData.sendData["REGR_DEPT_CD"] = 'x';
          requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
          requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
          requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
          requestData.sendData["AMDR_DEPT_CD"] = "x";
          let response = await this.common_postCall(requestData);
          this.initUserRegistSbmitCallBack(response);
        },
        initUserRegistSbmitCallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
          this.common_alert("정상처리되었습니다.");
          this.getGrid2();
          this.selArtrGroupNm = "";
          this.$refs.form.resetValidation()

        },
        async popSubmit(){

          if(!this.$refs.form.validate()) return false
          let requestData = {
            headers: {},
            sendData:{}
          };
          if(this.type == "I"){ //신규
            this.initUserSbmit();
          }else{//수정
            // header 세팅
            requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/modify";
            requestData.headers["METHOD"] = "modify";
            requestData.headers["TYPE"] = "BIZ_SERVICE";
            requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
            requestData.headers["ASYNC"] = false;


            requestData.sendData = {};

            requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
            requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
            requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
            requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
            requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
            requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
            requestData.sendData["REGR_DEPT_CD"] = 'x';
            requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
            requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            requestData.sendData["AMDR_DEPT_CD"] = "x";

          }
          let response = await this.common_postCall(requestData);
          this.popSubmitCallBack(response);
        },

        popSubmitCallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
        // this.gridDataText = data;
          this.common_alert("정상적으로 수정되었습니다.");
          this.$emit('hideAndGetList');
          this.getGrid2();
          return;
        },

        getGrid2CallBack(response){
          let header = response.HEADER;
          let data = response.DATA;
          this.gridDataText = data;
          //this.common_alert("정상처리 되었습니다.");
          this.$emit('hideAndGetList');
          return;
        },

        async fnInit() {
          let userId = this.$store.getters['userStore/GE_USER_ROLE'].userId;
          let userGrCd = this.$store.getters['userStore/GE_USER_ROLE'].userGroupCd;

          if (userGrCd == "SYSTEMMANAGER") {
            this.USER_AUTH_CHK = true;
          }else{
            this.USER_AUTH_CHK = false;
          }

        },

      },

      async mounted(){
        // 헤더 초기화 및 세팅
        await this.getGrid2();
        await this.fnInit();
      },

      computed: {
        initHeaders() {
          return {
            SERVICE: '',
            METHOD: "",
            TYPE: 'BIZ_SERVICE',
          };
        },
        paramData(){
          return {
            ATRT_GROUP_NM : this.param.data.ATRT_GROUP_NM,
            ETC_INFO01 : this.param.data.ETC_INFO01,
            MNGR_YN : this.param.data.MNGR_YN,
            ATRT_GROUP_ID : this.param.data.ATRT_GROUP_ID,
            ETC_INFO02 : this.param.data.ETC_INFO02,
            GROUP_XPLN : this.param.data.GROUP_XPLN,

        };
      }
    },
  };
</script>

<style>

</style>
